.mapYandex{
    .demo{
        background-color: #28292c !important;
        padding-top: 70px;
        h2{
            font-family: 'Akzidenz-Grotesk', sans-serif;
            margin-bottom: 23px;
        }
 
        div{
            text-align: center;
            margin-bottom: 20px;
        }
    
    }
}
 