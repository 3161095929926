.leg-massager {
	background-image: url('../../assets/images/06_back.png');

	.leg-massager__left {
		// display: flex;
		// align-items: center;
		.innerCol {
			display: flex;
			justify-content: left;
			img {
				width: 70%;
				filter: drop-shadow(8px 11px 8px hsla(0deg, 0%, 0%, 0.7));
			}
		}
	}
	.leg-massager__right {
		display: flex;
		align-items: center;
		.innerCol {
			h2 {
				font-family: 'Akzidenz-Grotesk', sans-serif;
				font-size: 48px;
				line-height: 1.7;
				font-weight: 900;
				margin-top: -90px;
			}
			p {
				letter-spacing: 0.3px;
				font-size: 18px;
				font-family: 'Akzidenz-Grotesk', sans-serif;
			}
			button {
				padding: 13px 33px;
				background: #169ea1;
				color: white;
				border: 2px solid #16faff;
				border-radius: 30px;
				margin-top: 50px;
			}
		}
	}

	.btnCol {
		display: none;
	}
}

@media (max-width: 1199px) {
	.leg-massager__left {
		margin-top: 85px !important;
		img {
			width: 90% !important;
		}
	}
	.leg-massager__right {
		.innerCol {
			h2 {
				line-height: 1.3 !important;
				margin-bottom: 25px;
			}
		}
	}
}
@media(min-width:768px){
    .leg-massager{
        .animated{
            width: 50%;
        }
    }
}
@media (max-width: 991px) {
	.leg-massager {
		.row {
			.leg-massager__left {
				margin-top: 0 !important;
			}

			.leg-massager__right {
				p {
					display: none !important;
				}
				button {
					display: none !important;
				}
			}
		}

		.btnCol {
			display: block;
			display: flex;
			justify-content: center;
			align-items: end;
			button {
				padding: 7px 25px;
				margin-top: 20px;
				background-color: #169ea1;
			}
		}
	}
}
@media (max-width: 767px) {
	.leg-massager {
		.row {
			display: flex;
			flex-direction: column-reverse;
			.leg-massager__left {
				margin-bottom: 15px !important;
				.innerCol {
					justify-content: center;
					img {
						width:70% !important;
					}
				}
			}
			.leg-massager__right {
				justify-content: center;
				h2 {
					font-size: 30px;
					margin-top: -30px;
				}
			}
		}
	}
}
