.back-massager {
	background-image: url('../../assets/images/05_back.png');
	

	.back-massager__left {
		display: flex;
		align-items: center;
		h1 {
			font-family: 'Akzidenz-Grotesk', sans-serif;
			font-size: 48px;
			line-height: 1.7;
			font-weight: 900;
			margin-top: -90px;
		}
		p {
			letter-spacing: 0.3px;
			font-size: 18px;
			font-family: 'Akzidenz-Grotesk', sans-serif;
		}
		button {
			padding: 13px 33px;
			background: #169ea1;
			color: white;
			border: 2px solid #16faff;
			border-radius: 30px;
			margin-top: 50px;
		}
	}
	.back-massager__right {
		display: flex;
		align-items: center;
		display: flex;
		justify-content: right;
		img {
			width: 90%;
			filter: drop-shadow(8px 11px 8px hsla(0deg, 0%, 0%, 0.7));
		}
	}
}

@media(min-width:768px){
    .iron{
        .animated{
            width: 50%;
        }
    }
}