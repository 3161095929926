* {
  padding: 0;
  margin: 0;
  list-style: none;
  outline: none;
  font-family: "Roboto", system-ui, Tahoma, sans-serif;
  box-sizing: border-box;
}

@font-face {
  font-family: "Akzidenz-Grotesk";
  src: url("./assets/fonts/4202-font.otf");
}

a {
  text-decoration: none;
  color: inherit;
}
a:hover {
  color: unset;
}

body {
  color: white;
}

.navBar {
  position: fixed;
  width: 100%;
  color: white;
  height: 60px;
  z-index: 9;

  .container {
    display: flex;
    justify-content: space-between;
    height: inherit;
    align-items: center;
    .nav__logo {
      h1 {
        font-family: "Akzidenz-Grotesk", sans-serif;
        margin-bottom: 0;
      }
    }
    .menu {
      display: flex;
      align-items: center;
      .menu-item {
        margin-left: 30px;
        font-size: 18px;
        font-size: 18px;
        font-family: Verdana, sans-serif;
      }
      .bottomLine {
        position: relative;
        &:after {
          content: "";
          width: 0%;
          height: 1px;
          background-color: white;
          position: absolute;
          bottom: -2px;
          left: 0;
          transition: 0.3s;
        }
        &:hover:after {
          width: 100%;
        }
      }
    }
    nav {
      button {
        span {
          background-image: url("assets/images/burger3.png");
          background-repeat: no-repeat;
          background-image: url("assets/images/burger3.png");
          background-repeat: no-repeat;
          display: none;
          width: 23px;
        }
      }
    }
  }
}

.aboutUs {
  background-color: #28292c;
  .row1 {
    padding-top: 100px;
    margin-bottom: 60px;
    span {
      padding: 7px;
      border-radius: 7px;
      &:hover {
        border: 1px solid #16faff;
      }
      .fa-chevron-left {
        margin-right: 10px;
      }
    }
  }
  .row2 {
    h2 {
      font-family: "Akzidenz-Grotesk";
      margin-bottom: 30px;
    }
    p {
      font-family: Verdana;
      font-weight: normal;
    }
  }
  .imgBlock {
    text-align: right;
    img {
      width: 80%;
    }
  }
}

.product {
  .title {
    text-align: center;
    margin-bottom: 50px;
    font-family: "Akzidenz-Grotesk";
  }
  .row3 {
    margin-top: 50px;
    h2 {
      margin-bottom: 20px;
    }
    div {
      div {
        margin-bottom: 20px;
      }
    }
  }
}

.comments {
  background-image: url("assets/images/06_back.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-bottom: 30px;
  min-height: 100vh;

  // background: #303030;
  .container {
    .row1 {
      padding-top: 100px;
      margin-bottom: 60px;
      span {
        padding: 7px;
        border-radius: 7px;
        &:hover {
          border: 1px solid #16faff;
        }
        .fa-chevron-left {
          margin-right: 10px;
        }
      }
    }
    .row2 {
      text-align: center;
      h3 {
        font-family: "Akzidenz-Grotesk";
        // margin-bottom: 30px;
      }
      .slider {
        .arrow--left {
          margin-left: -25px;
        }
        .arrow--right {
          margin-right: -25px;
        }
        .keen-slider__slide {
          display: flex;
          flex-direction: column;
          .userImg {
            border: 3px solid;
            border-radius: 50%;
            // padding: 10px;
            margin-bottom: 20px;
            img {
              border-radius: 50%;
              width: 80px;
            }
          }
          p {
            font-size: 17px;
          }
        }
      }
    }
  }
}

.section {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.notFound {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("./assets/images/not-found.jpg");
  height: 100vh;
}

.footer {
  padding: 30px 0;
  background-color: #000;
  height: auto;

  .container {
    height: 100%;

    .row {
      margin-left: 5px;
      width: 100%;
      .col-md-4 {
        ul {
          padding-left: 0;
          margin-top: 15px;
          display: flex;
          li {
            margin: 0 15px 10px 0;
            .svg-inline--fa.fa-lg {
              font-size: 1.5em;
            }
          }
        }
      }
      .contact {
        ul {
          display: block !important;
          span {
            margin-left: 10px;
          }
        }
      }
    }
  }
}

.offcanvas {
  background-color: #2ec0c4 !important;
  .offcanvas-header {
    position: relative;
    button {
      position: absolute;
      top: 25px;
      right: 25px;
      // display: none;
    }
    // span{
    //   background-image: url('assets/images/close2.png') !important;
    //   width: 23px;
    //   height: 23px;
    // }
  }
  .offcanvas-title {
    padding-top: 12px;
    height: 100%;
    display: flex;
    align-items: center;
    ul {
      height: 200px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: -100px;
      width: 100%;
      li {
        margin-bottom: 10px;
        a {
          font-size: 25px;
          display: block;
        }
      }
    }
  }
}

@media (min-width: 767px) {
  .comments {
    // background: #303030;
    .container {
      .row2 {
        .slider {
          .arrow--left {
            margin-left: -40px;
          }
          .arrow--right {
            margin-right: -40px;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .container {
    padding: 0 20px;
    .col-md-4 {
      text-align: center;
      margin-bottom: 20px;
      .socialIcons {
        display: flex;
        justify-content: center;
      }
      ul {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
      }
    }
    nav {
      margin-left: 18px;
      button {
        span {
          display: block !important;
        }
      }
    }
  }
}
