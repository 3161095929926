.iron {
	background-image: url('../../assets/images/05_back.png');

	.iron__left {
		display: flex;
		align-items: center;
		.innerCol {
			h2 {
				font-family: 'Akzidenz-Grotesk', sans-serif;
				font-size: 48px;
				line-height: 1.5;
				font-weight: 900;
				margin-top: -90px;
				margin-bottom: 25px;
			}
			p {
				letter-spacing: 0.3px;
				font-size: 18px;
				font-family: 'Akzidenz-Grotesk', sans-serif;
			}
			button {
				padding: 13px 33px;
				background: #169ea1;
				color: white;
				border: 2px solid #16faff;
				border-radius: 30px;
				margin-top: 50px;
			}
		}
	}
	.iron__right {
		justify-content: right;
		position: relative;
		display: flex;
		justify-content: center;
		img {
			width: 70%;
			filter: drop-shadow(8px 11px 8px hsla(0deg, 0%, 0%, 0.7));
		}
	}
	.btnCol {
		button {
			display: none;
		}
	}
}

@media (max-width: 1199px) {
	.iron__left {
		margin-top: 85px !important;
		.innerCol {
			h2 {
				line-height: 1.3 !important;
			}
		}
	}
	.iron__right {
		img {
			width: 100% !important;
		}
	}
}
@media(min-width:768px){
    .iron{
        .animated{
            width: 50%;
        }
    }
}

@media (max-width: 991px) {
	.iron__left {
		p {
			display: none !important;
		}
		button {
			display: none !important;
		}
	}

	.btnCol {
		display: flex;
		justify-content: center;
		align-items: end;
		button {
			display: block !important;
			padding: 13px 33px;
			background-color: #169ea1;
			color: white;
			border: 2px solid #16faff;
			border-radius: 30px;
			margin-top: 10px;
		}
	}
}

@media (max-width: 767px) {
	
	.row {
		// height: inherit;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		.iron__left {
			display: flex;
			justify-content: center;
			h2 {
				font-size: 34px !important;
				margin-top: -30px !important;
				text-align: center !important;
				font-size: 30px !important;
			}
			.seatTitle{
				font-size: 30px !important;
			}
		}
		.iron__right {
			text-align: center !important;
			margin-bottom: 10px !important;
			img {
				width: 80% !important;
			}
		}
		.btnCol {
			button {
				padding: 13px 33px;
				background-color: #169ea1;
				color: white;
				border: 2px solid #16faff;
				border-radius: 30px;
				margin-top: 10px;
			}
		}
	}
}
@media (max-width: 575px) {
	.row {
		.iron__left {
			display: unset;
			h2 {
				font-size: 22px !important;
			}
		}
		.btnCol {
			button {
				padding: 7px 25px;
				
			}
		}
	}
}
